import React, {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import StyledButton from '../../cmp/themed/StyledButton';
import {FormattedMessage} from 'react-intl';
import OAuthSignIn from '../OAuthSignIn';
import RenderError from '../components/RenderError';
import {post, setAccessToken} from '../../../api';
import SponsorName from '../components/SponsorName';
import RecaptchaRenderer from '../../cmp/RecaptchaRenderer';
import dayjs from 'dayjs';
import {
    getCurrentQueryObject,
    trimPostData,
    validateValueForInputMask,
} from '../../../tools/func';
import RenderFormInput from '../../cmp/formbuilder/RenderFormInput';
import {getItem} from '../../../tools/storage';

const emailValidator = new RegExp(/\S+@\S+\.\S+/);

export default function AccountCreationForm({
    page_handle,
    page_data
        }) {

    const allow_partner_registrations = useSelector((state) => state.settings.allow_partner_registrations !== undefined ? state.settings.allow_partner_registrations : true);
    if (!allow_partner_registrations) {
        return <p className="alert alert-danger"><FormattedMessage
            id={"We are not accepting registrations at this moment. Kindly check back later"}/></p>;
    }
    return <AccountCreationFormInternal page_handle={page_handle} page_data={page_data} />
}

function useSocialTracking(){
    const {
        gtag_signup_conversion_id,
        fb_signup_conversion_event,
        snapchat_pixel_id
    } = useSelector((state)=>{
        return {
            gtag_signup_conversion_id: state.settings.gtag_signup_conversion_id,
            fb_signup_conversion_event: state.settings.fb_signup_conversion_event || 'AccountCreated',
            snapchat_pixel_id: state.settings.snapchat_pixel_id
        }
    })
    const triggerPixelConversion = useCallback(()=>{
        if (gtag_signup_conversion_id) {
            try {
                window.gtag('event', 'conversion', {
                    'send_to': gtag_signup_conversion_id,
                });
            } catch (e) {
            }
        }
        if(snapchat_pixel_id){
            try{
                window.snaptr('track','SIGN_UP')
            }catch(e){

            }
        }
        if (fb_signup_conversion_event) {
            try {
                window.fbq('track', fb_signup_conversion_event);
            } catch (e) {
            }
        }
    },[gtag_signup_conversion_id, fb_signup_conversion_event, snapchat_pixel_id])

    return {
        triggerPixelConversion
    }
}

function AccountCreationFormInternal({page_handle, page_data}) {
    const [loading, setLoading] = useState(false)
    const {
        triggerPixelConversion
    } = useSocialTracking()
    // used in mobile app.
    // So if a user token is sent in the URL, we set it as the user token and hide the name, email and password fields in the form
    const user_token = useMemo(()=>{
         return new URLSearchParams(window.location.search).get('user_token')
    },[])
    const {
        signupFields,
        isEmbedded,
        signupButtonText,
        recaptcha_enabled
    } = useSelector(useCallback((state)=>{
        const {isEmbedded, signupFields, signupPageTitle, signupButtonText} = page_data ? page_data : state.settings;
        const fields =  (signupFields ? JSON.parse(signupFields) : defaultFieldsMap);
        return {
            recaptcha_enabled: state.settings.recaptcha_enabled,
           // signupFields:user_token ? fields.filter((i)=>i.id !== 'name' && i.id !== 'email' && i.id !== 'password') : fields,
            signupFields: fields,
            isEmbedded,
            signupButtonText: signupButtonText || 'Create Account',
        }
    },[page_data, user_token]))
    const [data, setData] = useState(getCurrentQueryObject())
    const hiddenFieldsFromQueryParameter = useMemo(()=>{
        const {hide} = getCurrentQueryObject()
        if(hide && typeof hide==="string") {
            return hide?.split(",").map((i) => i.trim())
        }
    },[])
    const [error, setError] = useState('')
    const onChange = (key) => (e) => {
        setData((existing)=>{
            return {
                ...existing,
                [key]:e?.target?.value
            }
        })
    }
    const handleFormSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (!shouldEnable()) {
            return;
        }
        if(data.password.length < 5){
            setError('Password should be at least 5 characters long')
        }

        const postData = {};
        signupFields.forEach((item) => {
            if (item.isEnabled && data[item.id]) {
                postData[item.id] = data[item.id];
            }
        });
        setLoading(true)
        setError('')
        const ref = data.ref || getItem('ref');
        const utm_source = (getItem('utm_source') || page_handle);
        const toPost = trimPostData(postData);
        if(postData['date_of_birth']){
            toPost['date_of_birth'] = dayjs(postData['date_of_birth']).format('YYYY-MM-DD')
        }
        triggerPixelConversion()
        post('/register_new', {
            ...toPost,
            page_handle,
            ref,
            utm_source : utm_source ? ["undefined","null"].indexOf(utm_source) > -1 ? undefined : utm_source : undefined,
            recaptcha_response: data.recaptcha_response
        }).then((data) => {
            if (data.access_token) {
                if(window.goaffpro_mobile_app_signup_complete){
                    window.goaffpro_mobile_app_signup_complete()
                    return;
                }
                setAccessToken(data.access_token)
                const params = new URL(document.location.href).searchParams;
                const after_signup_redirect = params.get('redirect');
                if(after_signup_redirect){
                    if(window.top === window.self){
                        window.location = after_signup_redirect;
                    }else{
                        if(after_signup_redirect.startsWith("https://")){
                            // only redirect full URLS to top
                            window.top.location = after_signup_redirect
                        }else{
                            window.location = after_signup_redirect
                        }
                    }
                }else {
                    window.location = `/${isEmbedded ?
                        '?embedded=1' :
                        ''}`;
                }
            } else {
                const {code} = data;
                setError(code)
                setLoading(false)
            }
        }).catch(() => {
            setLoading(false)
        });
    }

    const shouldEnable = () => {
        const {email, password} = data
        const filteredFields = signupFields.filter(({isRequired, isEnabled}) => isEnabled && isRequired)
        for (let x = 0; x < filteredFields.length; x++) {
            const {id, isRequired, type, inputMask, min_age, max_age, isEnabled} = filteredFields[x];
            const val = typeof data[id] === "string" ? data[id].trim() : data[id]
            if(val && (min_age || max_age)){
                const dob = dayjs(val);
                if(dob.isValid()){
                    const now = dayjs();
                    const min = min_age ? now.subtract(min_age, 'years') : null;
                    const max = max_age ? now.subtract(max_age, 'years') : null;
                    if(min && dob.isAfter(min)){
                        return false;
                    }
                    if(max && dob.isBefore(max)){
                        return false;
                    }
                }
            }

            if (isEnabled && isRequired && (!val || String(val).length === 0)) {
                console.log('false length',id)
                return false;
            }

            if(id === "phone" && isRequired && isEnabled && val?.length < 7){
                console.log('false', id)
                return false;
            }
            // check for input mask
            if((!type || type === "text") && inputMask && String(inputMask).trim()){
                // validate input
                if (!validateValueForInputMask(inputMask, val)) {
                     console.log('false for mask', id, val, inputMask)
                    return false;
                }
            }
            if(type === "fileupload" && !val?.url){
                console.log('false for fileupload')
                return false;
            }
        }
        return email && password
            && emailValidator.test(email)
            && (recaptcha_enabled ? data.recaptcha_response : true)
    }

        return (
            <div>

                <form onSubmit={handleFormSubmit}>
                    <div className={'row flex-wrap'}>
                        {
                            signupFields.filter(({isEnabled}) => isEnabled).
                                filter(
                                    ({id}) => !hiddenFieldsFromQueryParameter ||
                                        hiddenFieldsFromQueryParameter.indexOf(
                                            id) === -1).
                                map((item, index) =>
                                    (<RenderFormInput key={item.id} {...item}
                                                      value={data[item.id] || ''}
                                                      onChange={onChange(
                                                          item.id)}
                                    />),
                                )
                        }
                    </div>
                    {
                        recaptcha_enabled &&
                        <RecaptchaRenderer
                            onChange={onChange('recaptcha_response')}
                        />
                    }
                    {
                        error && <RenderError code={error}/>
                    }
                    <div
                        className="d-flex justify-content-between align-items-center">
                        <div className={'d-flex align-items-center'}>
                            <StyledButton loading={loading}
                                          className={'btn btn-primary'}
                                          disabled={!shouldEnable()}
                                          onClick={handleFormSubmit}>
                                <FormattedMessage id={signupButtonText}/>
                            </StyledButton>
                            <SponsorName/>
                        </div>
                        <small className={'text-danger'}><FormattedMessage
                            id={'required'}/></small>
                    </div>
                </form>
                {
                    !user_token ?
                        <OAuthSignIn/> : null
                }
            </div>
        );
}


const defaultFieldsMap = [
    {
        label: 'Name',
        id: 'name',
        isRequired: false,
        isDefault: true,
        isEnabled: true
    },
    {
        label: 'Email',
        id: 'email',
        isRequired: true,
        isDefault: true,
        type: 'input',
        isEnabled: true
    },
    {
        label: 'Password',
        id: 'password',
        isRequired: true,
        isDefault: true,
        type: 'password',
        isEnabled: true
    }
];
